<template>
  <div v-if="isLoading">
    <Spinner  classes="mx-auto my-4"></Spinner>
  </div>
  <div v-else>
    <p v-if="isServiceEmpty" class="text-dark my-5 py-5 text-center">{{ $t('notifications.error.serviceNotFound') }}</p>
    <section v-else>
      <BackButton :scrollToSticky="100" top="0rem"/>
      <card class="card-height bg-dark mb-0">
        <img
          v-if="mainPicture"
          slot="raw-content"
          class="card-img img-main"
          :src="mainPicture"
          @error="replaceByDefault"
          :alt="$t('alt.service')"
          width="100%"
          height="100%"
        />

        <div slot="raw-content" class="card-img-overlay mt-3 pb-0">
          <div class="row justify-content-end align-items-end h-100">
            <div class="price-size">
              <span
                class="background-price text-white font-weight-bold w-100 px-3 py-2"
              >
                {{
                  service.price && service.on_promotion
                    ? `${(
                        (service.price * (100 - service.discount)) /
                        100
                      ).toFixed(2)}€`
                    : `${service.price ? service.price.toFixed(2) : ""}€`
                }}
              </span>
            </div>
          </div>
        </div>
      </card>
      <div class="row justify-content-center m-0">
        <card class="col-12 col-sm-8">
          <h2 class="card-title text-capitalize font-weight-bold">
            {{ service.name }}
            <span class="font-weight-light time-size">[{{ service.time }}m]</span>
          </h2>
          <p class="card-text">{{ service.description }}</p>
        </card>
      </div>

      <h3
        v-if="galleryPictures.length > 0"
        class="text-center text-dark font-weight-bold my-3"
      >
        {{ $t("galleryPictures") }}
      </h3>
      <div class="row justify-content-center m-0">
        <div class="col-12 col-md-8">
          <PicturesCarousel :images="galleryPictures"></PicturesCarousel>
        </div>
      </div>

      <div class="row justify-content-center m-0 mb-4">
        <base-button
          round
          type="tertiary"
          class="col-10 col-sm-6 w-100 px-0"
          @click="goToServiceBooking(slug)"
        >
          {{ $t("book") }}
        </base-button>
      </div>
    </section>
  </div>
</template>

<script>
import { Card, Spinner, PicturesCarousel, BackButton } from "@/components/index";
import { mapState } from 'vuex';
import defaultImage from "../../assets/img/img-default.png";

export default {
  name: "StoreService",
  data: () => {
    return {
      route: "/services",
      slug: null,
      service: [],
      mainPicture: null,
      galleryPictures: [],
      screenWidth: screen.width,
      isLoading: true,
      isServiceEmpty: false,
    };
  },
  computed: {
    ...mapState(["whichPopover", "defaultTitle"])
  },
  methods: {
    async getItem() {
      const route = `${this.route}/${this.slug}/${this.serviceSlug}`;
      let response = await this.axios.get(route);
      if (response && response?.data?.status === "success") {
        this.service = response?.data?.data?.services || [];
        this.service?.pictures?.forEach((picture) => {
          if (picture.type === "1") this.mainPicture = picture.url;
          else this.galleryPictures.push(picture.url);
        });
      }
      this.isServiceEmpty = response?.data?.status !== "success" || !response;
      this.isLoading = false;
    },
    goToServiceBooking(slug) {
      this.$router.push({
        name: "serviceBooking",
        params: { slug: slug, serviceSlug: this.serviceSlug },
      });
    },
    replaceByDefault(event) {
      event.target.src = defaultImage;
    },
  },
  async beforeMount() {
    this.slug = this.$route.params.slug;
    this.serviceSlug = this.$route.params.serviceSlug;
    if (this.slug != null) {
      await this.getItem();
    }
    console.log(this.service);
  },
  metaInfo() {
    if(this.service?.name) {
      return {
        title: `${this.service.name} - ${this.service.store.name} - ${this.service.store.locality} - ${this.$t('bookAppointmentsOnline')}`,
        meta: [
          {name: 'og:title', property: 'og:title', content: this.defaultTitle},
          {name: 'og:type', property: 'og:type', content: 'website'},
          {name: 'og:url', property: 'og:url', content: `${process.env.VUE_APP_URL.substring(0, process.env.VUE_APP_URL.length - 1)}${this.$route.fullPath}`},
          {name: 'og:description', property: 'og:description', content: this.$t('vueMeta.storeService.description', [this.service.store.name, this.service.store.locality])},
          {name: 'description', content: this.$t('vueMeta.storeService.description', [this.service.store.name, this.service.store.locality])},
          {name: 'og:image', property: 'og:image', content: this.service.pictures[0]?.url || ''},
          {name: 'og:site_name', property: 'og:site_name', content: process.env.VUE_APP_AS_NAME}
        ]
      }
    }
  },
  components: {
    BackButton,
    Card,
    Spinner,
    PicturesCarousel
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/white-dashboard.scss';

.card-height {
  height: 37vh;
}
.img-main {
  height: 100%;
  object-fit: contain;
}
.background-price {
  background-color: $secondary;
  border-radius: 30px;
}
.price-size {
  width: 91.25px;
  margin-bottom: -10px;
  z-index: 99;
}
.time-size {
  font-size: 15px;
}
.img-gallery {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
@media (max-width: 768px) {
  .img-gallery {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .btn-small-font {
    font-size: 0.675rem;
  }
}
</style>